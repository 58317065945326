import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { NotificationSettings } from '../models/notification-settings';

@Injectable({ providedIn: 'root' })
export class SettingsService {
    private settingsEndpoint = '/api/notificationSettings';

    constructor(private http: HttpClient) { }

    async createNotificationSettings(settings: NotificationSettings): Promise<number> {
        const settings$: Observable<number> =
            this.http.post<number>(`${environment.apiUrl}${this.settingsEndpoint}`, settings);
        return await firstValueFrom(settings$);
    }

    async updateNotificationSettings(settings: NotificationSettings): Promise<number> {
        const put$: Observable<number> =
            this.http.put<number>(`${environment.apiUrl}${this.settingsEndpoint}/${settings.id}`, settings);
        return await firstValueFrom(put$);
    }

    async getNotificationSettingsByUser(user: string): Promise<NotificationSettings> {
        const settings$: Observable<NotificationSettings> =
            this.http.get<NotificationSettings>(`${environment.apiUrl}${this.settingsEndpoint}?user=${user}`);
        return await firstValueFrom(settings$);
    }
}
